import { acceptHMRUpdate, defineStore } from 'pinia';
import { findDownloadIdInSlug } from './helper/findDownloadIdInSlug';
import type {
  DownloadsQueryModel,
  DownloadsResponseModel
} from '~/server/api/downloads/index.get';
import { type DownloadsPerCategoryQueryModel } from '~/server/api/downloads/perCategory.get';
import { isDefined } from '~/utils/guards/isDefined';
import type { DownloadEntryModel } from '~/lib/DownloadService/model/DownloadEntryModel';
import type { LocationQuery, RouteParams } from 'vue-router';
import type {
  DownloadsFacetSearchQueryModel,
  DownloadsFacetSearchResponseModel
} from '~/server/api/downloads/facetSearch';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';
import type {
  DownloadByIdQueryModel,
  DownloadByIdResponseModel
} from '~/server/api/downloads/byId.get';

export type DownloadStoreState = {
  context: 'documentCenter' | 'pdp';
  query: string;
  staticFilters: Record<string, string[]>;
  filters: Record<string, string[]>;
  loading: boolean | undefined;
  activeModal: string | undefined | boolean;
  download: DownloadEntryModel | undefined;
  downloads: DownloadsResponseModel | null;
  offsetCounter: Record<string, number>;
  documentationLink: Record<string, string> | undefined;
  facetSearchAbortController: AbortController | null;
};

export const FILTER_TYPES_WITH_SEARCH = ['products', 'series'];

const CATEGORY_ANALYTICS_MAP: Record<string, string> = {
  'downloadCategory.data': 'document-type_filter_selection',
  'medialink.locale': 'document-lang_filter_selection',
  products: 'document-product_filter_selection',
  series: 'document-series_filter_selection'
};

export const useDownloadStore = defineStore('downloads', {
  state: (): DownloadStoreState => ({
    context: 'documentCenter',
    query: '',
    staticFilters: {},
    filters: {},
    loading: undefined,
    activeModal: false,
    download: undefined,
    downloads: null,
    offsetCounter: {},
    documentationLink: {},
    facetSearchAbortController: null
  }),
  getters: {
    mergedFilters: (state) => ({
      ...state.filters,
      ...state.staticFilters
    }),
    hasResults: (state): boolean =>
      Object.values(state.downloads?.results ?? {}).some(
        (values) => !isEmpty(values)
      ),
    validFilters: (state): Record<string, string[]> => {
      const validKeys = state.downloads?.filters.map(({ name }) => name) ?? [];

      return Object.fromEntries(
        Object.entries(state.filters).filter(([key]) => validKeys.includes(key))
      );
    },
    hasActiveFilters: (state): boolean =>
      Object.values(state.filters).some((values) => !isEmpty(values))
  },
  actions: {
    async updateFiltersFromRoute(routeQuery: LocationQuery): Promise<void> {
      const routeFilters: Record<string, string[]> = {};

      for (const key in routeQuery) {
        // "sort" inside an encoded json in an url (e.g. filter={"sort" ...})
        // will lead to a firewall error. therefore, we ignore this parameter.
        // @see https://gcp.baslerweb.com/jira/browse/DBP-988
        if (key === 'sort') {
          continue;
        }

        const value = routeQuery[key];
        routeFilters[key] = ensureArray(value)
          .map((value) => value?.toString())
          .filter(isDefined);
      }

      if (JSON.stringify(this.filters) !== JSON.stringify(routeFilters)) {
        this.filters = routeFilters;
      }
    },
    /**
     * Handles a route change to determine any active download id.
     *
     * @param params The new route params.
     */
    async handleRouteChange(params: RouteParams) {
      const { $globalPageSettings } = useNuxtApp();
      const documentCenterSlug =
        $globalPageSettings.value?.documentCenterPage?.metadata?.slug;
      if (!documentCenterSlug) {
        return;
      }

      const downloadId = findDownloadIdInSlug(
        ensureArray(params.slug),
        documentCenterSlug
      );

      if (downloadId) {
        await this.openModal(downloadId);
      } else {
        this.activeModal = undefined;
      }
    },
    async openModal(downloadId: string): Promise<void> {
      if (this.downloads?.results) {
        for (const key in this.downloads?.results) {
          this.downloads?.results[key].hits.filter((downloadItem) => {
            if (downloadId === downloadItem.id) {
              this.download = downloadItem;

              return;
            }
          });
        }
      }

      if (!this.download) {
        await this.fetchDownloadById(downloadId);
      }

      this.activeModal = downloadId;

      // return this.download;
    },
    async fetchDownloads(): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();
      this.loading = true;

      // XXX: abort controller
      // XXX: error handling
      this.downloads = await $fetch<DownloadsResponseModel>('/api/downloads', {
        method: 'get',
        query: {
          query: this.query,
          locale: $resolvedLocale.value!,
          filters: JSON.stringify(this.mergedFilters)
        } satisfies DownloadsQueryModel
      });

      // init pageCounter
      this.offsetCounter = Object.keys(this.downloads.results).reduce(
        (reducer, category) => {
          reducer[category] = 1;

          return reducer;
        },
        {} as Record<string, number>
      );
      this.loading = false;
    },
    async checkForExistingDownloads(sku: string): Promise<boolean> {
      const { $resolvedLocale } = useNuxtApp();
      const filters = {
        skus: [sku]
      };

      // XXX: abort controller
      // XXX: error handling
      const downloads = await $fetch<DownloadsResponseModel>('/api/downloads', {
        method: 'get',
        query: {
          locale: $resolvedLocale.value!,
          filters: JSON.stringify(filters)
        } satisfies DownloadsQueryModel
      });
      return Object.values(downloads.results).some(
        (values) => values.hits.length > 0
      );
    },
    async fetchDownloadById(id: string): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();
      const logger = useLogger();

      try {
        this.download = await $fetch<DownloadByIdResponseModel>(
          '/api/downloads/byId',
          {
            method: 'get',
            query: {
              id,
              locale: $resolvedLocale.value!
            } satisfies DownloadByIdQueryModel
          }
        );
      } catch (e) {
        logger.error(`could not load document download by id "${id}"`, e);
      }
    },
    async fetchMore(category: string): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();

      this.offsetCounter[category] =
        this.downloads?.results?.[category]?.hits.length ?? 0;

      // XXX: abort controller
      // XXX: error handling
      const moreDownloads = await $fetch<DownloadsResponseModel>(
        '/api/downloads/perCategory',
        {
          method: 'get',
          query: {
            query: this.query,
            locale: $resolvedLocale.value!,
            filters: JSON.stringify(this.mergedFilters),
            category: category,
            limit: '5',
            offset: this.offsetCounter[category]?.toString() ?? '0'
          } satisfies DownloadsPerCategoryQueryModel
        }
      );

      if (
        this.downloads &&
        this.downloads.results &&
        category in this.downloads.results
      ) {
        // spread more downloads into existing ones per category
        this.downloads.results[category].hits = [
          ...this.downloads.results[category].hits,
          ...moreDownloads.results[category].hits
        ];
      }
    },
    handleFormValueChange(key: string, value: string | string[]) {
      const { $analytics } = useNuxtApp();

      this.filters = {
        ...this.filters,
        [key]: ensureArray(value)
      };
      $analytics?.pushToDataLayer({
        action: CATEGORY_ANALYTICS_MAP[key],
        category: 'downloads_filter_selection',
        event: 'click_Internal',
        label: value
      });
      this.updateRoute(this.filters);
    },
    updateRoute(filters: Record<string, string[]>) {
      const router = useRouter();

      router.push(this.createUrlParams(filters));
    },
    createUrlParams(filters?: Record<string, string[]>) {
      const clonedFilters = this._cloneFilters(filters ?? this.filters);

      return {
        query: clonedFilters
      };
    },
    _cloneFilters(
      filters?: Record<string, string[]>
    ): Record<string, string[]> {
      return JSON.parse(JSON.stringify(filters ?? this.filters));
    },
    resetFilters() {
      this.filters = {};
      this.updateRoute(this.filters);
    },
    createNewUrlParamsFromFilter(key: string, valueToRemove: string) {
      const clonedFilters = this._cloneFilters();
      const values = this.filters[key];
      const arrayValues = ensureArray(values).filter(isDefined).map(String);

      clonedFilters[key] = arrayValues.filter(
        (value) => value && !valueToRemove.includes(value)
      );

      return this.createUrlParams(clonedFilters);
    },
    closeModal(): void {
      this.activeModal = false;
    },
    afterCloseModal(): void {
      const logger = useLogger();
      const router = useRouter();

      if (this.context === 'pdp') {
        router
          .replace({
            hash: '#tab-panel-tab-documents',
            query: this.filters,
            replace: true
          })
          .catch((e) =>
            logger.error(
              'failed to navigate during close modal in download store',
              e
            )
          );

        return;
      }

      const locale = useLocale();
      const { $globalPageSettings } = useNuxtApp();
      const path =
        $globalPageSettings.value?.documentCenterPage?.metadata?.slug;

      if (!path) {
        logger.error('no document center page defined - can not close modal');

        return;
      }

      setTimeout(
        () =>
          router.replace({
            path: buildUrlString(locale.value, path),
            query: this.filters
          }),
        300
      );
    },
    async facetSearch(
      facet: string,
      facetQuery: string
    ): Promise<DownloadsFacetSearchResponseModel | undefined> {
      if (!this.facetSearchAbortController?.signal.aborted) {
        this.facetSearchAbortController?.abort();
      }

      const abortController = new AbortController();
      this.facetSearchAbortController = abortController;

      const { $resolvedLocale } = useNuxtApp();

      try {
        return await $fetch<DownloadsFacetSearchResponseModel>(
          '/api/downloads/facetSearch',
          {
            ...DEFAULT_FETCH_OPTIONS,
            method: 'get',
            query: {
              facet,
              facetQuery,
              query: this.query,
              locale: $resolvedLocale.value!,
              filters: JSON.stringify(this.mergedFilters)
            } satisfies DownloadsFacetSearchQueryModel,

            signal: this.facetSearchAbortController?.signal
          }
        );
      } catch (e) {
        if (!abortController.signal.aborted) {
          useLogger().error('failed to fetch facets search results', e);
        }
      }
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDownloadStore, import.meta.hot));
}
